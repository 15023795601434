//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    CContent: () => import("@/components/CContent"),
    XModal: () => import("@/components/XModal"),
    CBtn: () => import("@/components/CBtn"),
    CTitle: () => import("@/components/CTitle"),
  },
  props: {
    //规则版本信息
    selRow: {
      type: Object,
      default: null,
    },
    teamValue: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isAdd: null,
      pageData: {
        pageIndex: 1,
        pageSize: 20,
        dataNum: 0,
        currentPage: 1,
      }, //分页
      loading: false,
      tableData: [],
      roleForm: {
        name: null, //名称
        formnames: [{ str: "" }],
        id: null,
      },
      titleInfo: {
        btnShow: [
          { type: "addBtn", ishow: true },
          { type: "startBtn", ishow: false, disabled: true },
          { type: "closeBtn", ishow: false, disabled: true },
          { type: "delBtn", ishow: false, disabled: true },
        ],
        dropDown: {
          searchInput: {
            placeholder: "",
          },
        },
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入角色名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getNameData();
  },
  methods: {
    /**
     * 设置每页条数
     */
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.pageData.pageIndex = 1;
      this.getNameData();
    },
    /**
     * 去往某一页
     */
    handleCurrentChange(val) {
      this.pageData.pageIndex = val;
      this.getNameData();
    },
    /**
     * 添加别名
     */
    addClick() {
      this.isAdd = true;
      this.roleForm.Name = null;
      this.$modal.show("editRole");
    },
    /**
     * 删除别名
     */
    handleCancle(row) {
      this.$confirm("此操作将删除该别名, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/ProgressAlia/DelProgressAlias.ashx", {
              Ids: [row.Id],
              teamId: this.teamValue,
            })
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getNameData();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /**
     * 编辑操作
     */
    handleEdit(row) {
      this.isAdd = false;
      console.log(row);
      this.roleForm.name = row.AliasName;
      this.roleForm.id = row.Id;
      this.roleForm.formnames = row.FormNames.map((m) => {
        return {
          str: m,
        };
      });
      this.$modal.show("editRole");
    },
    /**
     * 确认编辑/添加
     */
    handleConfirm() {
      this.$refs.roleForm.validate((val) => {
        if (val) {
          if (this.isAdd) {
            this.addConfirm();
          } else {
            this.editConfirm();
          }
        } else {
          return;
        }
      });
    },
    /**
     * 编辑
     */
    editConfirm() {
      const fm = this.roleForm.formnames.filter((m) => m.str);
      const data = {
        Id: this.roleForm.id,
        name: this.roleForm.name,
        formnames: fm.map((m) => m.str),
        teamId: this.teamValue,
        configId: this.selRow.Id,
      };
      this.$refs.xmodal.loadBtn(true);
      this.$http
        .post("/ProgressAlia/EditProgressAlias.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.$notify({
              message: "编辑成功",
              type: "success",
            });
            this.$modal.hide("editRole");
            this.getNameData();
          }
        })
        .finally(() => this.$refs.xmodal.loadBtn(false));
    },
    /**
     * 添加
     */
    addConfirm() {
      const fm = this.roleForm.formnames.filter((m) => m.str);
      this.$refs.xmodal.loadBtn(true);
      this.$http
        .post("/ProgressAlia/AddProgressAlias.ashx", {
          name: this.roleForm.name,
          formnames: fm.map((m) => m.str),
          teamId: this.teamValue,
          configId: this.selRow.Id,
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.$message({
              type: "success",
              message: "添加进程窗口别名成功",
            });
            this.$modal.hide("editRole");
            this.getNameData();
          }
        })
        .finally(() => this.$refs.xmodal.loadBtn(false));
    },
    delFormname(index) {
      this.roleForm.formnames.splice(index, 1);
    },
    /**
     * 获取别名数据
     */
    getNameData() {
      this.loading = true;
      const data = {
        name: null,
        pageIndex: this.pageData.pageIndex,
        pageSize: this.pageData.pageSize,
        teamId: this.teamValue,
        configId: this.selRow.Id,
      };
      this.$http
        .get("/ProgressAlia/QueryProgressAliasList.ashx", {
          params: data,
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.tableData = resp.data.Data.map((m) => {
              return {
                AliasName: m.AliasName,
                FormNames: JSON.parse(m.FormNames),
                Id: m.Id,
              };
            });
            this.pageData.dataNum = resp.data.TotalCount;
          }
        })
        .finally(() => (this.loading = false));
    },
    tableHeaderStyle(row) {
      return "table-header";
    },
    attenceCellStyle() {
      return {
        textAlign: "center",
      };
    },
    attenceHeaderStyle() {
      return {
        background: "#F5F7FA",
        textAlign: "center",
        fontWeight: "bold",
        color: "#909399",
      };
    },
  },
};
